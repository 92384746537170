<template>
   <!-- Footer Section -->
   <VaSection color="primary" class="footer-section">
      <p>&copy; 2024 My AI Solutions. All Rights Reserved.</p>
    </VaSection>
</template>

<script setup lang="ts">

</script>

<style>
.footer-section {
  text-align: center;
  padding: 20px 0;
  color: white;
}
</style>